@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
@import url(https://fonts.googleapis.com/css?family=Alata);
body {
  margin: 0;
  overflow: hidden !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* ===================================================================================================================*/
/* Custom CSS ========================================================================================================*/
/* ===================================================================================================================*/


/* ===================================================================================================================*/
/* Custom Fonts ======================================================================================================*/
/* ===================================================================================================================*/

@font-face {
  font-family: 'Sweet Cake Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Sweet Cake Regular'), url(/static/media/SweetCake.966abfd6.woff) format('woff');
}

@font-face {
  font-family: "yorkwhiteletter";
  font-weight: normal;
  font-style: normal;
  src: url(/static/media/YorkWhiteLetter.9db8ac1c.woff) format("woff");
}

/* @font-face {
  font-family: 'Code Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Code Pro Bold'), url('./assets/fonts/CodeProBold.woff') format('woff');
} */

@font-face {
  font-family: 'CodeBoldRegular';
  font-style: normal;
  font-weight: normal;
  src: local('CodeBoldRegular'), url(/static/media/CodeBoldRegular.1a42782a.woff) format('woff');
}

@font-face {
  font-family: 'Campus Personal Use';
  font-style: normal;
  font-weight: normal;
  src: local('Campus Personal Use'), url(/static/media/CampusPersonalUse.9dc4ce4c.woff) format('woff');
}

@font-face {
  font-family: 'CodeProRegular';
  font-style: normal;
  font-weight: normal;
  src: local('CodeProRegular'), url(/static/media/CodeProRegular.934e7010.woff) format('woff');
}
@font-face {
  font-family: 'CodeProDLight';
  font-style: normal;
  font-weight: normal;
  src: local('CodeProDLight'), url(/static/media/CodeProLight.05c7bc98.woff) format('woff');
}
@font-face {
  font-family: 'CodeProBold';
  font-style: normal;
  font-weight: normal;
  src: local('CodeProBold'), url(/static/media/CodeProBold.a032d302.woff) format('woff');
}
/* ===================================================================================================================*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

