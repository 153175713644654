body {
  margin: 0;
  overflow: hidden !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');
@import url('https://fonts.googleapis.com/css?family=Alata');
/* ===================================================================================================================*/
/* Custom CSS ========================================================================================================*/
/* ===================================================================================================================*/


/* ===================================================================================================================*/
/* Custom Fonts ======================================================================================================*/
/* ===================================================================================================================*/

@font-face {
  font-family: 'Sweet Cake Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Sweet Cake Regular'), url('./assets/fonts/SweetCake.woff') format('woff');
}

@font-face {
  font-family: "yorkwhiteletter";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/YorkWhiteLetter.woff") format("woff");
}

/* @font-face {
  font-family: 'Code Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Code Pro Bold'), url('./assets/fonts/CodeProBold.woff') format('woff');
} */

@font-face {
  font-family: 'CodeBoldRegular';
  font-style: normal;
  font-weight: normal;
  src: local('CodeBoldRegular'), url('./assets/fonts/CodeBoldRegular.woff') format('woff');
}

@font-face {
  font-family: 'Campus Personal Use';
  font-style: normal;
  font-weight: normal;
  src: local('Campus Personal Use'), url('./assets/fonts/CampusPersonalUse.woff') format('woff');
}

@font-face {
  font-family: 'CodeProRegular';
  font-style: normal;
  font-weight: normal;
  src: local('CodeProRegular'), url('./assets/fonts/CodeProRegular.woff') format('woff');
}
@font-face {
  font-family: 'CodeProDLight';
  font-style: normal;
  font-weight: normal;
  src: local('CodeProDLight'), url('./assets/fonts/CodeProLight.woff') format('woff');
}
@font-face {
  font-family: 'CodeProBold';
  font-style: normal;
  font-weight: normal;
  src: local('CodeProBold'), url('./assets/fonts/CodeProBold.woff') format('woff');
}
/* ===================================================================================================================*/
